'use strict';

export const validate = function() {
  $('form').each(function(i, e) {
    $(e).validate({
      ignore: ':hidden',
      invalidHandler: function(event, validator) {
        const alert = $('#kt_form_msg');
        alert.parent().removeClass('kt-hidden');
        const targetId = validator.errorList[0]['element'].id;
        KTUtil.scrollTo(targetId, -200);
      },
    });
  });
};

// メールアドレス Validation 用の正規表現
// 仕様書はこちら: https://techouse.atlassian.net/wiki/spaces/CH/pages/3665789230/CHW+Validation
const VALID_EMAIL_ADDRESS_PATTERN = /^[ 　]*(?=.{1,64}@)(([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)|(\".+\"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})[ 　]*$|^[ 　]*$/;

export const validatesEmail = (value, _element) => {
  return VALID_EMAIL_ADDRESS_PATTERN.test(value);
};
