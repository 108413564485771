import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  run(event) {
    if (event.params.buttonIndex) {
      this.buttonTargets[Number(event.params.buttonIndex)].click();
    } else {
      this.buttonTargets[0].click();
    }
  }
}
